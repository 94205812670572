import React from 'react'
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

const containerStyle = {
  width: '100%',
  height: '400px'
};

const position = {
  lat: -7.7037836,
  lng: 110.4639443
};

function MyComponent() {
  return (
    <LoadScript
      googleMapsApiKey="AIzaSyBzzmOeuXc3hNoKurg_WM0C38qpFabpUfw"
    >
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={position}
        zoom={17}
      >
        <Marker
            position={position}
        />
      </GoogleMap>
    </LoadScript>
  )
}

export default React.memo(MyComponent);