import { Container, makeStyles, Typography } from '@material-ui/core';
import React from 'react'
import LazyLoad from 'react-lazyload';
import Maps from './Maps';

const useStyles = makeStyles((theme) => ({
    address: {
      paddingBottom: theme.spacing(4)
    },
  }));

function Address () {
    const classes = useStyles();
    return (
        <Container maxWidth="md" className={classes.address}>
        <Typography variant="h5" align="center" color="textPrimary" gutterBottom>
              Alamat
        </Typography>
        <Typography variant="body1" align="center" color="textPrimary" gutterBottom>
            Area Sawah, Bimomartani, Kec. Ngemplak, Kabupaten Sleman, Daerah Istimewa Yogyakarta 55584
        </Typography>
        <LazyLoad>
            <Maps />
        </LazyLoad>
        </Container>
    )
}

export default Address;