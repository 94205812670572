import React from 'react';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import LazyLoad from 'react-lazyload';

const useStyles = makeStyles((theme) => ({
  cardGrid: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardMedia: {
    paddingTop: '56.25%', // 16:9
  }
}));

export default function Album({length}) {
  const classes = useStyles();
  const cards = [...Array(length).keys()].map(item => item + 1);
  const URL = 'https://assets.jasaderekmobiljogja.com/bobby-towing_';

  const onClickCard = (key) => {
      window.open(`${URL + key}.jpeg`, '_blank').focus();
  };

  return (
    <React.Fragment>
      <main>
        <Container className={classes.cardGrid} maxWidth="md">
          <Grid container spacing={4}>
            {cards.map((card, index) => (
              <Grid item key={index} xs={12} sm={6} md={4}>
                <Card className={classes.card}>
                <LazyLoad>
                  <CardMedia
                    className={classes.cardMedia}
                    image={`${URL + card}.jpeg`}
                    title={`Bobby Towing ${card}`}
                    onClick={() => onClickCard(card)}
                  />
                </LazyLoad>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </main>
    </React.Fragment>
  );
}