import { Link, makeStyles, Typography } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme) => ({
    footer: {
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(4),
    },
  }));

function Footer() {
    const classes = useStyles();
    return (
    <footer className={classes.footer}>
        <Typography variant="body2" color="textSecondary" align="center">
        {'Copyright'} &copy; {' '}
        <Link color="inherit" href="https://jasaderekmobiljogja.com">
            Bobby Towing
        </Link>{' '}
        {new Date().getFullYear()}
        {'. '}
        Developed by ArchieRinn.
        </Typography>
    </footer>
    );
}

export default Footer;